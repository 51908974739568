<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center mb-1">
        <div class="mb-4 md:mb-0 mr-4">
          <div class="flex">
            <vs-button v-if="!selectable && $can('create_reimburse')" color="primary" type="border" icon-pack="feather" icon="icon-plus" class="px-3" @click="modalAddActive = true">Tambah</vs-button>
          </div>
        </div>

        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <!--limit chooser-->
          <PagingLimit :currentPage="table.page" :limit="table.limit" :total="table.totalItems" @changeLimit="onPageLimitChange"/>

          <div class="w-full sm:w-auto flex">
            <!--search-->
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 w-full" placeholder="Cari" v-model="table.search" @keyup="onSearch"/>

            <!--actions-->
            <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
              <vs-button class="mb-4 md:mb-0 px-3 py-3 flex items-center justify-center" type="filled" icon-pack="feather" icon="icon-more-vertical"/>
              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <ExportCsv v-if="$refs.mainTable" :data="$refs.mainTable._props.data" />
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
      </div>

      <!--loading-->
      <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !table.loading }"/>

      <!--table-->
      <vs-table
        ref="mainTable"
        :class="{ 'animate-pulse': table.loading }"
        :sst="true"
        :data="table.rows"
        @sort="onSort"
        stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap" v-if="selectable"></vs-th>
          <vs-th class="whitespace-no-wrap" v-if="!selectable">Aksi</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="kode_proyek">Proyek</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="no_reimburse">No. Reimbursement</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="tgl_reimburse">Tanggal</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="nama_staf">Diajukan Oleh</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="judul_reimburse">Judul Reimburse</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="status">Status</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="status_approval">Status Approval</vs-th>
          <vs-th class="whitespace-no-wrap">Progress Approval</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="total_reimburse">Total Reimburse</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="total_bayar">Total Paid</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="u.username">Created By</vs-th>
          <vs-th class="whitespace-no-wrap" sort-key="created_at">Created At</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm align-top">
            <vs-td v-if="selectable">
              <vs-button class="py-2 px-3" type="border" @click="onSelected(item)">Pilih</vs-button>
            </vs-td>
            <vs-td v-if="!selectable">
              <div class="flex">
                <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
                  <vs-button class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                  <vs-dropdown-menu>
                    <vs-dropdown-item class="p-1" @click="showModalView(item)"><span class="whitespace-no-wrap" v-if="$can('view_reimburse')">View</span></vs-dropdown-item>
                    <vs-dropdown-item class="p-1" :disabled="item.status_approval !== 'DITERIMA'" @click="print(item.id)">Print</vs-dropdown-item>
                    <vs-dropdown-item v-if="item.status_approval !== 'DITOLAK' && item.status_approval !== 'DITERIMA' && item.id_jurnal === null && $can('update_reimburse')" class="p-1" @click="showModalEdit(item)"><span class="whitespace-no-wrap">Edit</span></vs-dropdown-item>
                    <vs-dropdown-item v-if="item.status_approval !== 'DITOLAK' && item.status_approval !== 'DITERIMA' && item.id_jurnal === null && $can('delete_reimburse')" class="p-1" @click="confirmDelete(item.id)"><span class="whitespace-no-wrap text-danger">Hapus</span></vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.kode_proyek }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.no_reimburse }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.tgl_reimburse }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.nama_staf }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.judul_reimburse }}</vs-td>
            <vs-td class="whitespace-no-wrap">
              <div class="flex items-center">
                <div class="text-white rounded-sm text-center items-center px-1 rounded" :class="'bg-' + item.status_color">
                  <span class="text-xs">{{ item.status }}</span>
                </div>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <div class="flex items-center">
                <div class="text-white rounded-sm text-center items-center px-1 rounded" :class="'bg-' + item.status_approval_color">
                  <span class="text-xs">{{ item.status_approval }}</span>
                </div>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap"><span class="underline cursor-pointer" @click="showModalApproval(item)">Lihat Progress</span></vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.total_reimburse | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.total_bayar | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.username }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.created_at }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <!--paging-->
      <div>
        <vs-pagination class="text-sm mt-6 overflow-x-scroll hidden sm:block" v-model="table.page" @change="onPageChange" :total="totalPages"/>
        <div class="flex w-full justify-end mt-6 justify-around sm:hidden">
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-left" @click="table.page > 1 && table.page--; onPageChange"/>
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-right" @click="table.page < totalPages && table.page++; onPageChange"/>
        </div>
      </div>
    </vx-card>

    <!--modals-->
    <ReimburseAdd v-if="!selectable" :isActive.sync="modalAddActive" @success="getData"/>
    <ReimburseEdit v-if="!selectable" :isActive.sync="modalEdit.active" @success="getData" :idReimburse="modalEdit.item.id"/>
    <ReimburseView v-if="!selectable" :isActive.sync="modalView.active" @success="getData" :idReimburse="modalView.item.id"/>
    <ModalApprovalProgress :isActive.sync="modalApproval.active" :type="modalApproval.type" :id-ref="modalApproval.idRef"/>
  </div>
</template>

<script>
import ReimburseRepository from '@/repositories/procurement/reimburse-repository'
import PagingLimit from '@/views/components/paging-limit/PagingLimit'
import ModalApprovalProgress from '@/views/components/approval-progress/ModalApprovalProgress'
import _ from 'lodash'
import printoutMixin from '@/utilities/mixins/printout-mixin'

export default {
  name: 'Reimburse',
  components: {
    ReimburseAdd: () => import('@/views/pages/procurement/reimburse/ReimburseAdd'),
    ReimburseEdit: () => import('@/views/pages/procurement/reimburse/ReimburseEdit'),
    ReimburseView: () => import('@/views/pages/procurement/reimburse/ReimburseView'),
    ExportCsv: () => import('@/views/components/export-csv/ExportCsv'),
    PagingLimit,
    ModalApprovalProgress
  },
  props: {
    selectable: { default: false, type: Boolean },
    externalFilter: { default: null, type: Object }
  },
  mixins: [printoutMixin],
  watch: {
    externalFilter (newVal, oldVal) {
      if (newVal && !_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.getData()
      }
    }
  },
  data () {
    return {
      modalAddActive: false,
      modalEdit: {
        active: false,
        item: {}
      },
      modalView: {
        active: false,
        item: {}
      },
      filter: {},
      table: {
        loading: false,
        page: 1,
        limit: 10,
        totalItems: 0,
        sort: '',
        search: '',
        rows: []
      },
      modalApproval: {
        active: false,
        type: null,
        idRef: null
      }
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.table.totalItems / this.table.limit)
    }
  },
  methods: {
    getData () {
      this.table.loading = true
      const params = (({ page, limit, search, sort }) => ({ page, limit, search, sort }))(this.table)

      params.filter = JSON.stringify({
        only_authorized_user: 1,
        ...this.filter
      })

      if (this.externalFilter) {
        params.externalFilter = JSON.stringify(this.externalFilter)
      }

      ReimburseRepository.get(params)
        .then(response => {
          this.table.rows = response.data.data.data
          this.table.totalItems = response.data.data.total
          if (this.table.page > response.data.data.last_page) {
            this.table.page = response.data.data.last_page
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    showModalApproval (item) {
      this.modalApproval.type = item.tipe === 'HRGA' ? 'REIMBURSE-HRGA' : 'REIMBURSE-OPERATIONAL'
      this.modalApproval.idRef = item.id
      this.modalApproval.active = true
    },

    delete (id) {
      ReimburseRepository.delete(id)
        .then(response => {
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus data ini?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    },

    print (id) {
      this.showPrintOutFile('REIMBURSE', { id_reimburse: [id] })
    },

    showModalEdit (item) {
      this.modalEdit.item = item
      this.modalEdit.active = true
    },

    showModalView (item) {
      this.modalView.item = item
      this.modalView.active = true
    },

    onPageChange () {
      this.getData()
    },

    onPageLimitChange (limit) {
      this.table.limit = limit
      if (this.table.page > this.totalPages) {
        this.table.page = this.totalPages
      }
      this.getData()
    },

    onSelected (item) {
      const itemCopy = Object.assign({}, item)
      this.$emit('selected', itemCopy)
    },

    onSort (key, order) {
      this.table.sort = (key === null || order === null) ? '' : `${key} ${order}`
      this.getData()
    },

    onSearch: _.debounce(function (e) {
      this.getData()
    }, 500)
  }
}
</script>
